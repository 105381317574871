<template>
  <div class="map">
    <header :class="['header', stage === 'onboarding' && 'header--centered']">
      <Topbar :tooltips="topbarTooltips" />
    </header>

    <transition name="fade">
      <div
        v-show="stage === 'onboarding'"
        class="overlay"
        @click="handleOverlayClick"
      />
    </transition>

    <transition name="fade">
      <div
        v-show="!mapInitialized"
        class="splash"
      >
        <Logo :to="undefined" />
        <p class="splash__label">Загрузка карты...</p>
      </div>
    </transition>

    <EventsMap :showControls="stage !== 'onboarding'" />

    <router-view></router-view>

    <CookieConsent />
  </div>
</template>

<script>
import store from '@/store';

import Logo from '@/components/Logo.vue';
import Topbar from '@/components/Topbar.vue';
import EventsMap from '@/components/EventsMap.vue';
import CookieConsent from '@/components/CookieConsent.vue';

const tooltipInitConfig = {
  search: null,
  create: null,
};

const tooltipCreatingConfig = {
  search: null,
  create: {
    content: 'Выйти из режима создания',
    shown: true,
    theme: 'primary',
    offset: [27, 5],
  },
};

const tooltipOnboardingConfig = {
  search: {
    content: 'Ищите интересные мероприятия',
    shown: true,
    placement: 'top-start',
    theme: 'primary',
    offset: [25, 5],
  },
  create: {
    content: 'Создавайте новые',
    shown: true,
    theme: 'primary',
    offset: [27, 5],
  },
};

export default {
  name: 'Map',
  metaInfo: {
    title: 'Карта событий',
    titleTemplate: '%s',
  },
  data() {
    return {
      searchTooltipTimeout: 0,
      createTooltipTimeout: 0,
      topbarTooltips: tooltipInitConfig,
    };
  },
  computed: {
    mapInitialized() {
      return this.$store.state.map.initialized;
    },
    stage: {
      set(newStage) {
        this.$store.commit('updateStage', newStage);
      },
      get() {
        return this.$store.state.app.stage;
      },
    },
  },
  watch: {
    stage() {
      clearTimeout(this.searchTooltipTimeout);
      clearTimeout(this.createTooltipTimeout);

      switch (this.stage) {
        case 'creating:mark':
          this.topbarTooltips = tooltipCreatingConfig;
          break;

        case 'onboarding':
          this.searchTooltipTimeout = setTimeout(() => {
            this.topbarTooltips = {
              search: tooltipOnboardingConfig.search,
              create: null,
            };
          }, 500);

          this.createTooltipTimeout = setTimeout(() => {
            this.topbarTooltips = tooltipOnboardingConfig;
          }, 1500);
          break;

        default:
          this.topbarTooltips = {
            search: null,
            create: null,
          };
      }
    },
  },
  mounted() {
    this.checkOnboarding();
  },
  updated() {
    this.checkOnboarding();
  },
  beforeRouteEnter(to, from, next) {
    if (to.path === '/map') {
      store.commit('updateStage', 'init');
    }

    if (store.state.user.firstVisit) {
      store.commit('registerFirstVisit');
      next({ path: '/onboarding' });
    } else {
      next();
    }
  },
  methods: {
    checkOnboarding() {
      if (!this.mapInitialized) {
        store.subscribe((action) => {
          if (action.type === 'mapInitialized') {
            if (this.$router.currentRoute.path === '/onboarding') {
              this.stage = 'onboarding';
            }
          }
        });
      } else if (this.$router.currentRoute.path === '/onboarding') {
        this.stage = 'onboarding';
      }
    },
    handleOverlayClick() {
      this.stage = 'init';
      this.$router.push('/map');
    },
  },
  components: {
    Logo,
    Topbar,
    EventsMap,
    CookieConsent,
  },
};
</script>

<style lang="scss" scoped>
.map {
  height: 100%;
}

.header {
  position: fixed;
  top: 2%;
  left: 50%;
  z-index: 1001;

  transform: translateX(-50%);
  transition: top cubic-bezier(0.22, 0.43, 0.63, 1.13) 0.3s;
}

.header--centered {
  top: calc(45% - 1.25rem);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;

  background: rgba(#000, 0.67);
}

.splash {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 3rem;
  background-color: var(--color-white);

  &__label {
    font-size: var(--fs-h4);
    font-weight: 700;
    text-align: center;
    color: var(--color-primary-50);
  }
}
</style>
