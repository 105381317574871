<template>
  <VMarkerCluster
    :options="options"
    @clusterclick="$emit('click', $event)"
  >
    <slot></slot>
  </VMarkerCluster>
</template>

<script>
import { DivIcon, Point } from 'leaflet';
import VMarkerCluster from 'vue2-leaflet-markercluster';

export default {
  name: 'EventsCluster',
  data() {
    return {
      options: {
        animate: true,
        animateAddingMarkers: true,
        showCoverageOnHover: false,
        spiderfyOnMaxZoom: false,
        zoomToBoundsOnClick: false,
        iconCreateFunction: (cluster) => {
          const childCount = cluster.getChildCount();
          let c = ' my-marker-cluster-';
          let size = [40, 40];
          if (childCount < 10) {
            c += 'small';
          } else if (childCount < 100) {
            c += 'medium';
            size = [50, 50];
          } else {
            c += 'large';
            size = [60, 60];
          }

          return new DivIcon({
            html: `<div class="leaflet-cluster">${childCount}</div>`,
            className: `marker-cluster${c}`,
            iconSize: new Point(...size),
          });
        },
      },
    };
  },
  components: {
    VMarkerCluster,
  },
};
</script>
