<template>
  <LControl
    class="leaflet-control leaflet-control--logout"
    position="topright"
  >
    <button
      class="leaflet-control__button"
      @click="handleLogout"
    >
      <Icon icon="logout" />
    </button>
  </LControl>
</template>

<script>
import { LControl } from 'vue2-leaflet';
import { mapActions } from 'vuex';

import Icon from '@/components/Icon.vue';

export default {
  name: 'LogoutControl',
  methods: {
    handleLogout() {
      this.logoutUser();
      this.$router.push('/login');
    },
    ...mapActions(['logoutUser']),
  },
  components: {
    LControl,
    Icon,
  },
};
</script>
