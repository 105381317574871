<template>
  <nav class="topbar">
    <a
      :href="searchPath"
      :class="[
        'button',
        stage === 'search:form' && 'button--close',
        filterApplied && 'button--active',
      ]"
      v-tooltip="tooltips.search"
      @click.prevent="handleSearchClick"
    >
      <span class="visually-hidden">Поиск событий</span>
      <Icon :icon="searchIcon" />
    </a>

    <div class="logo">
      <Logo />
    </div>

    <a
      :href="addPath"
      :class="[
        'button',
        stage.startsWith('creating') && 'button--close',
        stage === 'creating:mark' && 'button--active',
      ]"
      v-tooltip="tooltips.create"
      @click.prevent="handleAddClick"
    >
      <span class="visually-hidden">Добавить событие</span>
      <Icon :icon="addIcon" />
    </a>
  </nav>
</template>

<script>
import Logo from '@/components/Logo.vue';
import Icon from '@/components/Icon.vue';

export default {
  name: 'Topbar',
  props: {
    tooltips: Object,
  },
  data() {
    return {
      searchIcon: 'search',
      searchPath: '/map/find',
      addIcon: 'plus',
      addPath: '/map/new',
    };
  },
  computed: {
    stage: {
      set(newStage) {
        this.$store.commit('updateStage', newStage);
      },
      get() {
        return this.$store.state.app.stage;
      },
    },
    filterApplied() {
      return this.$store.state.events.filter.applied;
    },
  },
  watch: {
    stage() {
      if (this.stage === 'search:form') {
        this.searchIcon = 'plus';
        this.searchPath = '/map';
      } else if (this.filterApplied) {
        this.searchIcon = 'search';
        this.searchPath = '/map/find';
      } else {
        this.searchIcon = 'search';
        this.searchPath = '/map/find';
      }

      switch (this.stage) {
        case 'creating:mark':
          this.addPath = '/map/new';
          break;

        case 'creating:form':
          this.addPath = '/map';
          break;

        default:
          this.addPath = '/map/new';
      }
    },
  },
  mounted() {
    if (this.stage === 'search:form') {
      this.searchIcon = 'plus';
      this.searchPath = '/map';
    }
  },
  methods: {
    handleAddClick() {
      if (this.stage === 'creating:mark') {
        this.stage = 'creating:form';

        return;
      }

      if (this.stage === 'creating:form') {
        this.stage = 'init';

        this.$router.push({ name: 'Map' });
        return;
      }

      this.$router.push({ name: 'New' });
    },
    handleSearchClick() {
      this.$router.push(this.searchPath);
    },
  },
  components: {
    Logo,
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 16rem;
  height: 5rem;
  padding: 0 1rem;

  background-color: var(--color-white);

  border-radius: 2.375rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.logo {
  font-size: 1rem;
}

.button {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.5rem;
  height: 3.5rem;
  padding: 0;

  color: var(--color-white);
  background-color: var(--color-primary-50);

  font-size: 2.625rem;

  border: 0;
  border-radius: 50%;

  cursor: pointer;

  transition: border-color ease 0.15s;

  .icon {
    transition: transform ease 0.15s;
  }

  &:after,
  &:before {
    content: '';

    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;

    border: 1px solid var(--color-primary-50);
    border-radius: 50%;

    transform: translate(-50%, -50%) scale(1);
    transition: transform cubic-bezier(0.34, 1.56, 0.64, 1) 0.3s, border-color ease 0.15s;
  }

  &--close,
  &--active {
    &:after {
      transform: translate(-50%, -50%) scale(1.3);
    }
  }

  &--close {
    .icon {
      transform: rotate(45deg);
    }
  }

  &--active {
    background-color: var(--color-secondary-50);

    &:after,
    &:before {
      border-color: var(--color-secondary-50);
      animation: pulse ease 3s infinite both;
    }

    &:before {
      animation-delay: 2s;
    }
  }
}

@keyframes pulse {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }

  to {
    transform: translate(-50%, -50%) scale(1.7);
    opacity: 0;
  }
}
</style>
