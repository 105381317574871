<template>
  <LMarker
    ref="marker"
    :id="htmlId"
    :options="{ id }"
    :key="id"
    :lat-lng="latLng"
    :icon="icon"
    :class="{
      'event-marker--visible': visible,
    }"
    @click="$emit('click', id)"
  ></LMarker>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import L from 'leaflet';
import { LMarker } from 'vue2-leaflet';

export default {
  name: 'EventMarker',
  props: {
    id: Number,
    name: String,
    comment: String,
    latLng: Array,
    cost: Number || null,
    owned: Boolean,
  },
  data() {
    return {
      visible: false,
      icon: null,
    };
  },
  computed: {
    htmlId() {
      return `marker-${this.id}`;
    },
    isPartnerEvent() {
      const regexp = /Партн[е|ё]р RadiusFriend/;
      return regexp.test(this.comment);
    },
    isShowerEvent() {
      return this.name === 'Пущу к себе помыться!';
    },
    isPaid() {
      return Boolean(this.cost);
    },
  },
  mounted() {
    this.icon = this.createIcon();

    if (this.isPartnerEvent) {
      setTimeout(() => {
        const el = document.getElementById(this.htmlId);

        this.$observer.subscribe(el, (entry) => {
          if (entry.isIntersecting) {
            L.DomUtil.addClass(this.$refs.marker.mapObject._icon, 'event-marker__icon--jump');
          } else if (this.$refs.marker.mapObject._icon) {
            L.DomUtil.removeClass(this.$refs.marker.mapObject._icon, 'event-marker__icon--jump');
          }
        });
      }, 0);
    }
  },
  methods: {
    createIcon() {
      let classes = 'event-marker__icon';
      let iconId = 'marker';

      switch (true) {
        /* Бесплатное событие, созданное партнером */
        case this.isPartnerEvent && !this.owned && !this.cost:
          classes = 'event-marker__icon event-marker__icon--partner';
          break;

        /* Платное событие, созданное партнером */
        case this.isPartnerEvent && !this.owned:
          classes = 'event-marker__icon event-marker__icon--partner';
          iconId = 'marker-paid';
          break;

        /* Cобытие на отключение горячей воды,
         * отображаемое у пользователя, создавшего его
         */
        case this.isShowerEvent && this.owned:
          classes = 'event-marker__icon event-marker__icon--owned';
          iconId = 'marker-shower';
          break;

        /* Cобытие на отключение горячей воды,
         */
        case this.isShowerEvent:
          classes = 'event-marker__icon event-marker__icon--blue';
          iconId = 'marker-shower';
          break;

        /* Платное событие,
         * отображаемое у пользователя, создавшего его
         */
        case this.owned && !!this.cost:
          classes = 'event-marker__icon event-marker__icon--owned';
          iconId = 'marker-paid';
          break;

        /* Бесплатное событие,
         * отображаемое у пользователя, создавшего его
         */
        case this.owned:
          classes = 'event-marker__icon event-marker__icon--owned';
          break;

        /* Платное стандартное событие */
        case !!this.cost:
          classes = 'event-marker__icon';
          iconId = 'marker-paid';
          break;

        default:
      }

      /* Бесплатное стандартное событие */
      return L.divIcon({
        iconSize: [40, 55],
        iconAnchor: [20, 55],
        className: classes,
        html: `
          <svg id="${this.htmlId}">
            <use href="/sprite.svg#${iconId}" />
          </svg>
        `,
      });
    },
  },
  components: {
    LMarker,
  },
};
</script>

<style lang="scss">
.event-marker__icon {
  color: var(--color-marker-default);
  border: none !important;
  background: none !important;

  svg {
    display: block;
    width: 40px;
    height: 55px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  &--partner {
    color: var(--color-marker-partner);
  }

  &--owned {
    color: var(--color-marker-owned);
  }

  &--shower {
    color: var(--color-marker-shower);
  }

  &--jump {
    &:after {
      content: '';

      position: absolute;
      bottom: 0;
      left: 50%;

      display: block;
      width: 5px;
      height: 5px;

      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      transform: translate(-50%, 50%) scaleY(0.5);

      filter: blur(2px);

      animation: jump-shadow-2 ease-in-out 2s 3, fade-out 9s ease-in-out 0.5s 1;
      animation-fill-mode: both, forwards;
    }

    &:before {
      content: '';

      position: absolute;
      bottom: 0;
      left: 50%;

      display: block;
      width: 30px;
      height: 30px;

      background: rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      transform: translate(-50%, 50%) scaleY(0.5);

      filter: blur(10px);

      animation: jump-shadow-1 ease-in-out 2s 3, fade-out 9s ease-in-out 0.5s 1;
      animation-fill-mode: both, forwards;
    }

    svg {
      animation: jump ease-in-out 2s 3;
      animation-fill-mode: both;
    }
  }
}

@keyframes jump {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes jump-shadow-1 {
  from {
    transform: translate(-50%, 50%) scale(1, 0.5);

    filter: blur(5px);

    opacity: 1;
  }

  50% {
    transform: translate(-50%, 50%) scaleY(0.8, 0.5);

    filter: blur(15px);

    opacity: 1;
  }

  to {
    transform: translate(-50%, 50%) scaleY(1, 0.5);

    filter: blur(5px);

    opacity: 1;
  }
}

@keyframes jump-shadow-2 {
  from {
    opacity: 1;
  }

  40%,
  60% {
    opacity: 0.1;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>
