<template>
  <LMarker
    v-if="stage === 'creating:mark' && event.latLng.length"
    ref="marker"
    :lat-lng="event.latLng"
    :icon="icon"
  >
    <LPopup :options="popupOptions">
      <div>Вы уверены что хотите разместить событие здесь?</div>
      <div class="new-event-popup__actions">
        <button
          type="button"
          class="new-event-popup__button new-event-popup__button--cancel"
          @click="resetNewEventPosition"
        >
          <Icon icon="cross" />
        </button>

        <button
          type="button"
          class="new-event-popup__button new-event-popup__button--confirm"
          @click="confirmNewEventPosition"
        >
          <Icon icon="check" />
        </button>
      </div>
    </LPopup>
  </LMarker>
</template>

<script>
import L from 'leaflet';
import { LMarker, LPopup } from 'vue2-leaflet';
import { mapState, mapActions, mapMutations } from 'vuex';

import Icon from '@/components/Icon.vue';

export default {
  name: 'NewEventMarker',
  data() {
    return {
      icon: L.divIcon({
        iconSize: [32, 32],
        iconAnchor: [16, 16],
        className: 'new-event-marker__icon',
        html: `
          <svg>
            <use href="/sprite.svg#marker-target" />
          </svg>
        `,
      }),
      popupOptions: {
        className: 'new-event-popup',
        offset: [140, 190],
        closeButton: false,
      },
    };
  },
  computed: {
    ...mapState({
      stage: (state) => state.app.stage,
      event: (state) => state.events.new,
    }),
  },
  stage() {
    if (this.stage !== 'creating:mark') return;

    this.updateNewEvent({
      latLng: [],
    });
  },
  methods: {
    async setNewEventPosition($event) {
      await this.updateNewEvent({
        latLng: [$event.latlng.lat, $event.latlng.lng],
      });

      this.$refs.marker.mapObject.openPopup();

      const address = await this.getLocationByLatLng($event.latlng);

      this.updateNewEvent({
        address,
      });
    },
    async confirmNewEventPosition() {
      try {
        const id = await this.createEvent();

        this.$refs.marker.mapObject.closePopup();

        this.resetNewEvent();

        this.$router.push({ name: 'Event', params: { eventId: id } });

        this.fetchEvents();
      } catch (e) {
        console.log(e);
      }
    },
    resetNewEventPosition() {
      this.$refs.marker.mapObject.closePopup();

      this.updateNewEvent({
        latLng: [],
      });
    },
    ...mapMutations(['updateNewEvent', 'resetNewEvent']),
    ...mapActions(['createEvent', 'getLocationByLatLng']),
  },
  components: {
    LMarker,
    LPopup,
    Icon,
  },
};
</script>

<style lang="scss">
.new-event-marker__icon {
  svg {
    display: block;
    width: 32px;
    height: 32px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
}

.new-event-popup {
  margin: 0;

  .leaflet-popup-content-wrapper {
    padding: 0;

    color: var(--color-text);
    background: var(--color-white);
    text-align: center;
    border-radius: 1rem;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-content {
    margin: 0;
    width: 14.5rem !important;
    padding: 1.5rem;

    font-size: var(--fs-text);
    line-height: var(--lh-text);
  }

  &__actions {
    margin-top: 1.5rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  &__button {
    padding: 0;

    width: 2.625rem;
    height: 2.625rem;
    font-size: 2.2rem;
    line-height: 1;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    color: var(--color-white);

    border: 0;
    border-radius: 50%;

    cursor: pointer;

    &--cancel {
      justify-self: end;
      background: var(--color-gray-50);
    }

    &--confirm {
      justify-self: start;
      background: var(--color-primary-50);
    }
  }
}
</style>
