const getZoomFromScreenWidth = (width) => {
  switch (true) {
    case width <= 1024:
      return 2;

    case width <= 1600:
      return 2.4;

    case width < 2000:
      return 2.8;

    default:
      return 3.4;
  }
};

export default getZoomFromScreenWidth;
