<template>
  <LMap
    :class="{
      map: true,
      'map--show-controls': showControls,
    }"
    ref="eventsMap"
    :crs="crs"
    :zoom="14"
    :center="[55.755826, 37.6172999]"
    :minZoom="minZoom"
    :maxZoom="maxZoom"
    :options="mapOptions"
    :zoomAnimation="true"
    :maxBounds="maxBounds"
    :maxBoundsViscosity="1"
    @ready="handleMapReady"
    @click="handleMapClick"
    @locationfound="handleGeolocationSuccess"
    @locationerror="handleGeolocationError"
    @update:zoom="handleZoomUpdate"
  >
    <LControlLayers
      v-show="showControls"
      position="bottomleft"
      :collapsed="false"
      :hideSingleBase="true"
    ></LControlLayers>
    <LTileLayer
      v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :name="tileProvider.name"
      :visible="tileProvider.visible"
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
      :options="tileProvider.options"
      layer-type="base"
    />
    <LControlZoom
      v-if="showControls"
      position="bottomright"
      zoom-in-text="+"
      zoom-out-text="−"
    />
    <LogoutControl v-if="showControls && isLoggedIn" />
    <LControl
      v-if="showControls"
      class="leaflet-control-radius"
      position="bottomright"
    >
      <Icon icon="zone" />
      <span class="leaflet-control-radius__label">{{ radius }} км</span>
    </LControl>
    <LControl
      v-if="showControls"
      class="leaflet-control leaflet-control--locate"
      position="bottomright"
    >
      <button
        class="leaflet-control__button"
        @click="getGeolocation"
      >
        <Icon icon="geolocation" />
      </button>
    </LControl>
    <LControlAttribution
      v-if="showControls"
      position="bottomright"
      prefix=""
    />
    <EventsCluster
      v-if="hasEvents && stage !== 'creating:mark'"
      @click="handleClusterClick"
    >
      <EventMarker
        v-for="event in events"
        v-bind="event"
        :key="event.id"
        @click="handleMarkerClick"
      ></EventMarker>
    </EventsCluster>
    <NewEventMarker ref="newEvent" />
  </LMap>
</template>

<script>
import L from 'leaflet';
import {
  LMap,
  LControlZoom,
  LControlAttribution,
  LControl,
  LControlLayers,
  LTileLayer,
} from 'vue2-leaflet';
import { mapState, mapActions, mapMutations } from 'vuex';
import debounce from 'lodash/debounce';
import unionBy from 'lodash/unionBy';
import parseISO from 'date-fns/parseISO';

import EventMarker from '@/components/EventMarker.vue';
import NewEventMarker from '@/components/NewEventMarker.vue';
import EventsCluster from '@/components/EventsCluster.vue';
import Icon from '@/components/Icon.vue';
import LogoutControl from '@/components/LogoutControl.vue';

import 'leaflet/dist/leaflet.css';

import getZoomFromScreenWidth from '@/helpers/getZoomFromScreenWidth';

const WORLD_BOUNDS = new L.LatLngBounds(new L.LatLng(-85, -178), new L.LatLng(85, 178));

export default {
  name: 'Map',
  data() {
    return {
      tileLayerInitilized: false,
      minZoom: 3.4,
      maxZoom: 18,
      maxBounds: WORLD_BOUNDS,
      zoomControl: false,
      crs: L.CRS.EPSG3395,
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      mapOptions: {
        attributionControl: false,
        zoomControl: false,
        zoomSnap: 0.1,
        zoomDelta: 1,
      },
      tileProviders: [],
      unsubscribeEvent: null,
    };
  },
  props: {
    showControls: Boolean,
  },
  components: {
    LMap,
    LControlZoom,
    LControlAttribution,
    LControl,
    LControlLayers,
    LTileLayer,
    EventMarker,
    EventsCluster,
    Icon,
    NewEventMarker,
    LogoutControl,
  },
  computed: {
    stage: {
      set(newStage) {
        this.$store.commit('updateStage', newStage);
      },
      get() {
        return this.$store.state.app.stage;
      },
    },
    center: {
      set(newCenter) {
        this.$store.commit('updateMap', {
          center: newCenter,
        });
      },
      get() {
        return this.$store.state.map.center;
      },
    },
    radius: {
      set(newRadius) {
        this.$store.commit('updateMap', {
          radius: newRadius,
        });
      },
      get() {
        return (this.$store.state.map.radius / 1000).toFixed(1);
      },
    },
    zoom: {
      set(newZoom) {
        this.$store.commit('updateMap', {
          zoom: newZoom,
        });
      },
      get() {
        return this.$store.state.map.zoom;
      },
    },
    initialized: {
      set(value) {
        this.$store.commit('initializeMap', value);
      },
      get() {
        return this.$store.state.map.initialized;
      },
    },
    hasEvents() {
      return this.events.length > 0;
    },
    events() {
      return this.tileLayerInitilized ? this.allEvents : [];
    },
    ...mapState({
      isFirstVisit: (state) => state.user.isFirstVisit,
      isLoggedIn: (state) => Boolean(state.user.token),
      currentEvent: (state) => state.events.current,
      allEvents: (state) => {
        const created = state.events.created
          .filter((event) => parseISO(event.dateTime) > Date.now())
          .map((event) => ({ ...event, owned: true }));

        const union = unionBy(created, state.events.all, 'id');

        return union;
      },
    }),
  },
  watch: {
    stage() {
      if (this.stage !== 'creating:mark') {
        this.$refs.eventsMap.$el.classList.remove('map--creating');
        return;
      }

      this.$refs.eventsMap.$el.classList.add('map--creating');
    },
  },
  mounted() {
    if (typeof window !== 'undefined') {
      this.handleWindowResize();

      window.addEventListener('resize', this.handleWindowResize);
    }

    if (this.$route.params.eventId) {
      this.unsubscribeEvent = this.$store.subscribe(this.eventDetailsSubscriptionCallback);
    }
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleWindowResize);
    }

    if (this.unsubscribeEvent) {
      this.unsubscribeEvent();
    }
  },
  methods: {
    handleWindowResize() {
      this.minZoom = getZoomFromScreenWidth(window.innerWidth);
    },
    async handleMapReady() {
      if (!this.$route.params.eventId && !this.center) {
        await this.getGeolocation({ maxZoom: 14 });
      } else {
        this.initialized = true;
        this.$refs.eventsMap.mapObject.setView(this.center, this.zoom);
      }

      try {
        await this.checkYMaps();

        this.tileProviders.push({
          name: 'Схема',
          visible: true,
          url:
            'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.15-0-b210701140430&x={x}&y={y}&z={z}&scale={scale}&lang={lang}',
          attribution:
            '<a class="leaflet-attribution" href="https://yandex.ru" target="_blank"><svg class="leaflet-attribution__logo"><use href="sprite.svg#yandex"</svg></a>',
          options: {
            scale: window.devicePixelRatio,
            reuseTiles: true,
            lang: window.navigator.language,
          },
        });

        this.tileProviders.push({
          name: 'Спутник',
          visible: false,
          url:
            'https://core-sat.maps.yandex.net/tiles?l=sat&v=3.822.0&x={x}&y={y}&z={z}&scale={scale}&lang={lang}',
          attribution:
            '<a class="leaflet-attribution" href="https://yandex.ru" target="_blank"><svg class="leaflet-attribution__logo"><use href="sprite.svg#yandex"</svg></a>',
          options: {
            scale: window.devicePixelRatio,
            reuseTiles: true,
            lang: window.navigator.language,
          },
        });
      } catch (e) {
        console.log('Yandex Map error. Falling back with Open Street Map');

        this.tileProviders.push({
          name: 'Open Street Map',
          visible: true,
          url: this.url,
          attribution: this.attribution,
        });
      }

      this.tileLayerInitilized = true;

      this.handleZoomUpdate();
    },
    checkYMaps() {
      return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.src = 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.15-0-b210701140430&x=9570&y=4779&z=14&scale=2&lang=ru-RU';
        img.onload = () => resolve();
        img.onerror = () => reject();
      });
    },
    async getGeolocation({ maxZoom } = {}) {
      this.$refs.eventsMap.mapObject.locate({
        setView: true,
        maxZoom: maxZoom || 14,
        enableHighAccuracy: true,
      });
    },
    handleGeolocationSuccess(data) {
      this.updateMap({
        initialized: true,
        center: data.latlng,
      });
    },
    async handleGeolocationError() {
      console.log('Geolocation restricted. Using ip for getting approximate location...');

      try {
        const latLng = await this.getLocationByIp();
        this.$refs.eventsMap.mapObject.setView(latLng, 14);
        this.handleGeolocationSuccess({ latlng: latLng });
      } catch (e) {
        this.updateMap({
          initialized: true,
          center: [55.755826, 37.6172999],
        });
      }
    },
    handleMarkerClick(eventId) {
      if (this.$route.params.eventId !== eventId) {
        this.$router.push({ name: 'Event', params: { eventId } });
      }
    },
    handleClusterClick(event) {
      if (this.zoom === this.maxZoom) {
        const currentIds = this.$route.query.ids;

        const targetIds = event.layer
          .getAllChildMarkers()
          .map((item) => item.options.id)
          .sort()
          .join(',');

        if (currentIds !== targetIds) {
          this.$router.push(`/map/group?ids=${targetIds}`);
        }
      } else {
        event.layer.zoomToBounds({ padding: [120, 100] });
      }
    },
    async handleMapClick($event) {
      if (this.stage !== 'creating:mark') return;

      this.$refs.newEvent.setNewEventPosition($event);
    },
    getMapRadius() {
      const mapBoundEast = this.$refs.eventsMap.mapObject.getBounds().getSouthWest();
      const center = this.$refs.eventsMap.mapObject.getCenter();

      const mapDistance = mapBoundEast.distanceTo(center);

      return mapDistance;
    },
    handleZoomUpdate(currentZoom) {
      if (!this.initialized) return;

      let zoom;

      if (currentZoom) {
        zoom = Number(currentZoom.toFixed(1));
      }

      this.updateMap({
        center: this.$refs.eventsMap.mapObject.getCenter(),
        radius: Number(this.getMapRadius().toFixed(1)) || 1,
        ...(zoom ? { zoom } : {}),
      });

      this.debouncedFetchEvents();
    },
    debouncedFetchEvents: debounce(function () {
      this.fetchEvents();
    }, 500),
    eventDetailsSubscriptionCallback(mutation, state) {
      if (mutation.type !== 'receivedEventData') return;

      this.$refs.eventsMap.mapObject.flyTo(state.events.current.latLng, 14, {
        animate: true,
        duration: 1,
      });

      this.unsubscribeEvent();
    },
    ...mapMutations(['updateNewEvent', 'updateMap', 'fetchEvents']),
    ...mapActions(['fetchEvents', 'getLocationByIp', 'createEvent']),
  },
};
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 100%;
  overflow: hidden;

  &--creating {
    cursor: url("data:image/svg+xml,%3Csvg width='32' height='32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 21.2a5.2 5.2 0 100-10.4 5.2 5.2 0 000 10.4z' fill='%2341AD49' stroke='%23fff' stroke-width='2'/%3E%3Cpath d='M18.4 2V1h-4.8v3.048A12.211 12.211 0 004.048 13.6H1v4.8h3.048a12.21 12.21 0 009.552 9.552V31h4.8v-3.048a12.21 12.21 0 009.552-9.552H31v-4.8h-3.048A12.211 12.211 0 0018.4 4.048V2zM16 23.4c-4.08 0-7.4-3.32-7.4-7.4s3.32-7.4 7.4-7.4 7.4 3.32 7.4 7.4-3.32 7.4-7.4 7.4z' fill='%2341AD49' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E")
        16 16,
      auto;
  }
}

.leaflet-bottom:where(.leaflet-left) {
  margin-left: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;

  @media (min-width: 768px) {
    margin-left: 2rem;
    margin-bottom: 1.5rem;
  }
}

.leaflet-bottom:where(.leaflet-right) {
  margin-right: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-auto-flow: row;
  justify-items: end;
  grid-gap: 1rem;

  @media (min-width: 768px) {
    margin-right: 2rem;
    margin-bottom: 1.5rem;
    grid-auto-flow: column;
  }
}

.leaflet-top:where(.leaflet-right) {
  margin-right: 1rem;
  margin-top: 1rem;
  display: grid;
  grid-auto-flow: row;
  justify-items: end;
  grid-gap: 1rem;

  @media (min-width: 768px) {
    margin-right: 2rem;
    margin-top: 1.5rem;
    grid-auto-flow: column;
  }
}

.leaflet-control {
  float: none !important;
  margin: 0 !important;
}

.leaflet-control-zoom {
  order: -1;
  margin: 0 !important;

  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;

  border: 0 !important;

  @media (min-width: 768px) {
    order: 0;
    grid-auto-flow: column;
  }
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  display: flex !important;
  align-items: center;
  justify-content: center;

  width: 2.5rem !important;
  height: 2.5rem !important;

  font-family: var(--ff-base) !important;
  font-size: 1.5rem !important;
  line-height: 1 !important;
  font-weight: 700 !important;
  color: var(--color-primary-50) !important;

  background: var(--color-white);
  border: 0 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

  border-radius: 0.5rem !important;
}

.leaflet-control-attribution {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 800;
  background: none !important;
}

.leaflet-attribution {
  display: block;
}

.leaflet-attribution__logo {
  width: 1em;
  height: 0.4em;
  font-size: 2.5rem;
}

.leaflet-control-layers {
  display: none;

  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
  border: 0 !important;
  border-radius: 0.5rem !important;

  overflow: hidden;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

  .map--show-controls & {
    display: block;
  }
}

.leaflet-control-layers-base {
  display: flex;
}

.leaflet-control-layers-base label {
  display: block;
  cursor: pointer;
}

.leaflet-control-layers-selector {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.leaflet-control-layers-selector ~ span {
  display: block;
  height: 2.5rem;
  padding: 0 1.5rem;

  font-size: var(--fs-caption);
  line-height: 2.5rem;
  font-weight: 700;

  color: var(--color-primary-50);
  background-color: var(--color-white);

  transition: background-color linear 0.15s, color linear 0.15s;
}

.leaflet-control-layers-selector:checked ~ span {
  color: var(--color-white);
  background-color: var(--color-primary-50);
}

.leaflet-control--locate {
  order: -2;

  @media (min-width: 768px) {
    order: 0;
  }
}

.leaflet-control__button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;
  height: 2.5rem;
  padding: 0;

  font-family: var(--ff-base);
  font-size: 1.75rem;
  line-height: 1;
  font-weight: 700;
  color: var(--color-primary-50);

  background: var(--color-white);
  border: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  border-radius: 0.5rem;

  cursor: pointer;
}

.leaflet-control-radius {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;

  font-family: var(--ff-base);
  font-size: 1.75rem;
  line-height: 1;
  color: var(--color-primary-50);

  background: var(--color-white);
  border: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  border-radius: 0.5rem;
  cursor: default;

  &__label {
    font-size: var(--fs-caption);
    color: var(--color-text);
  }
}

.leaflet-cluster {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: var(--ff-base);
  font-size: var(--fs-h4);
  font-weight: bold;

  color: var(--color-white);
  background-color: var(--color-primary-50);
  border: 4px solid currentColor;
  border-radius: 50%;

  transition: color linear 0.15s, background-color linear 0.15s, border-color linear 0.15s;

  &:hover {
    color: var(--color-primary-50);
    background-color: var(--color-white);
  }
}
</style>
